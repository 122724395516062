import Otty from '@luketclancy/otterly/otty'
import morphdom from 'morphdom'


export default {
	...Otty,
	//this method has been changed to try and account for the insanity of the Table Of Contents.
	//Namely, it waits for any relevant editors to be loaded before it continues
	async scrollToLocationHashElement(loc){
		if(! loc.hash){ return false }

		let waitaway = Array.from(document.querySelectorAll('[data-hash-wait]'))

		for(let hw of waitaway) {
			if(hw?._unit?.editorLoadedPromise){
				await hw._unit.editorLoadedPromise	
			}
		}

		let e = document.getElementById(decodeURIComponent(loc.hash.slice(1)))
		if(e){
			await this.waitForImages()
			e.scrollIntoView()
			return true
		}
		return false
	},
	createStorageDoc(orienter, head){
		if(!Array.isArray(orienter)){ orienter = [orienter]}
		orienter = orienter.map( (x) => x.cloneNode(true) )
		let storeDoc = (new DOMParser()).parseFromString('<!DOCTYPE HTML> <html></html>', 'text/html')

		if(orienter.length == 1 && orienter[0].nodeName == "BODY"){
			storeDoc.body = orienter[0]
		} else {
			for(let o of orienter){storeDoc.body.appendChild(o)}
		}
		morphdom(storeDoc.head, head.cloneNode(true))
		return storeDoc
	},
	navigationHeadMorph(tempdocHead){
		//I started writing like this big thing about what to update and what to not using morphdom
		//unitl I figured out it really shouldn't change anyway?
		//Also the morphdom options are iffy, use the custom _morphOpts method instead.

		morphdom(document.head, tempdocHead, this.afterDive._morphOpts({permanent: '[href*="google"], [src*="google"], [id*="google"]'}))
	},
	//this method has been changed so that even if we cant find the hash, we 
	//dont leave the page
	async stopGoto(href){
		//Check scroll to hash on same page
		let loc = window.location
		href = new URL(href, loc)
		//hashes
		if(loc.origin == href.origin && href.pathname == loc.pathname && href.hash){
			await this.scrollToLocationHashElement(href)
			return true
		}
		//I wanted my subdomains to be counted too... apparently not possible...
		if(loc.origin != href.origin){
			window.location.href = href.origin
			return true
		}
		return false
	},
	async linkClickedF(e) {
		let href = e.target.closest('[href]')
		if(!href){ return }
		if(href.dataset.nativeHref != undefined){return}
		href = href.getAttribute('href')
		if(!this.isLocalUrl(href, -99)){return}

		//prevent default if we do not handle
		//cancel their thing
		e.preventDefault()
		e.stopPropagation()

		await this.goto(href)
		return
	},
	async pageReplace(tempdoc, scroll, url, beforeReplace, lastUrl){
		let befY = window.scrollY	
		//standardize tempdoc (accept strings)
		if(typeof tempdoc == "string") {
			tempdoc = (new DOMParser()).parseFromString(tempdoc,  "text/html")
		}

		let orienters, newOrienters
		[orienters, newOrienters] = this.getOrienters(tempdoc, url, lastUrl)

		//been having issues with the removed thing triggering as the observer is on the body which we are removing.
		// if(orienters[0].nodeName == "BODY"){
		// 	for(let unitEl of this.qsInclusive(orienters[0], '[data-unit]')){
		// 		this.stopError( () => unitEl._unit?.unitRemoved() )
		// 	}
		// }

		//set stored information for recreating current page
		let storeDoc =  this.createStorageDoc(orienters, document.head)

		//placement of this is important since we need to change the url and state after killing all the previous units
		//but before creating all the new units and event handles. For instance, this breaks _parse->dive[{"behavior": "repeat"}] since
		//the thing quick cancels since it thinks it left the page lol.
		if(beforeReplace){beforeReplace(storeDoc, befY)}

		// orienter.replaceChildren(...tmpOrienter.children)		
		this.navigationBodyChange(orienters, newOrienters)

		//morph the head to the new head. Throw into a different function for
		//any strangeness that one may encounter and 
		this.navigationHeadMorph(tempdoc.querySelector('head'))

		let shouldScrollToEl = (url && (!scroll))
		
		//handle scrolling
		let scrolled = false
		if(shouldScrollToEl){
			scrolled = await this.scrollToLocationHashElement(url)
		}
		if(!scrolled){
			if(scroll != 0){await this.waitForImages()}
			window.scroll(0, scroll)
		}
	},
	logData:  [],
	log: (data) => {
		let t = 'noTrace'
		try {
			t = new Error().stack
		} catch{ }

		console.log(data)

		otty.logData.push(JSON.stringify({
			time: (new Date).getTime(),
			loc: window.location.href,
			stack: t,
			data: data,
		}))
	}
}