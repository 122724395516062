// import {AfterDive} from '@luketclancy/otterly'

import AfterDive from './custom_after_dive.js'
import Generic  from './custom_generic.js'
import CustomOtty from "./custom_otty.js"
import CustomUnitHandler from "./custom_unit_handler.js"

//Units
import ArticleViewInfo from './units/article_view_info.js'
import Editor from './units/editor.js'
import Drop from './units/drop.js'
import Mention from './units/mention.js'
import PricingTable from './units/pricing_table.js'
import Account from './units/account.js'
import GoogleButton from './units/google_button.js'
import BgHandler from './units/bg_handler.js'
import Setup from './units/setup.js'
import AlterPostCard from './units/alter_post_card.js'

import 'bootstrap'
import tripwire_init from "./tripwire.js"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

let env = process.env.MF_ENV
let isDev = env === "development"
let csrfSelector = 'meta[name="csrf-token"]'
let csrfHeader = 'X-CSRF-Token'
const controllerContext = require.context("./controllers", true, /_controller\.js$/)

// let CustomGeneric  = Generic
let unitList = [Generic, Setup, BgHandler,  ArticleViewInfo, Editor, Drop, Mention, PricingTable, Account, GoogleButton, AlterPostCard]

let startApp = (v) => {
//kill old auth cookie
	if(isDev){
		document.cookie = "remember_me_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.localhost.io;"
	} else {
		document.cookie = "remember_me_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.midflip.io;"
	}

//the cool new thing
	window.midflip = {
		version: v,
		serviceWorkerVersion: v, //note: does not reset the cache
		isDev: isDev,
		pageSize: document.body.getAttribute("data-page-size"),
		isDm: () => window.matchMedia('(prefers-color-scheme: dark)').matches,
		getTabId: () => document.body.ds.tabId,
		save_tiptaps(){
			for(let editorEl of Array.from(document.querySelectorAll('[data-unit="Editor"]'))){
				editorEl._unit.saveContent()
			}
		},
		kill_any_loading_butts_below(el) {
			//need to counteract:
			// this.element.classList.add('loading_sequence')
			// e.currentTarget.style.display = "none"
			// e.currentTarget.classList.add("display-none")
	
			let loadings = el.querySelectorAll('[data-controller="loading"]')
			for(let loading of loadings) {
				if(loading.classList != null) {
					loading.classList.remove('loading_sequence')
				}
				loading.disabled = false
			}
			let butts = el.querySelectorAll('[data-load-butt]')
			for(let butt of butts) {
				butt.style.display = "block"
				butt.classList.remove('display-none')
			}
		},
		mob(el, func, opts={childList: true, subtree: true}){
			let mo = new MutationObserver(func)
			mo.observe(el, opts)
			return mo
		},
		rob(el, func, opts={}){
			let re = new ResizeObserver(func)
			re.observe(el, opts)
			return re
		},
		signedIn: () => (document.head.querySelector('[name="signed_in"]') ? true : false),
		initGoogle: async function(){
			otty.log('initGoogle_s')
			if(!google){
				otty.log('await script s')
				await new Promise((resolve2) => {
					document.head.querySelector('[src="https://accounts.google.com/gsi/client"]').addEventListener('load', resolve2)
				})
				otty.log('await script e')
			} else {otty.log('prexist')}

			let client_id = document.head.querySelector('[name="google_client"]').content
			let login_url = document.head.querySelector('[name="google_login_url"]').content
			otty.log(`init s ${client_id}, ${login_url}`)

			//setup google login settings
			google.accounts.id.initialize({
				client_id: client_id,
				callback: (x) => {
					otty.log(`callback s cred: ${x?.credential}`)
					let credential = x.credential
					out = otty.dive({url: login_url, formInfo: {credential}}).then((z) => {
						otty.log(`callback end ${JSON.stringify(z)}`)
					}, (z) => {
						otty.log(`callback error ${JSON.stringify(z)}`)
					})
					otty.log(`callback after`)
				},
				auto_prompt: "false"
			});
			otty.log('initGoogle_e')
			return google
		},
		getGoogle: async function(){
			otty.log('getGoogle_s')
			if(! this.googleProm){this.googleProm = this.initGoogle()}
			let ret = await this.googleProm
			otty.log('getGoogle_e')
			return ret
		},
		wait: (t) => new Promise((res) => setTimeout(res, t)),
		oneClickSignInPing: async function(){
			if(this.signedIn()){return}
			await this.wait(5000)
			await this.getGoogle()
			google.accounts.id.prompt()
		}
	}
	window.otty = CustomOtty.init(isDev, AfterDive, csrfSelector, csrfHeader)
	otty.unitHandler = CustomUnitHandler.init(Generic, unitList)
	otty.handleNavigation()
	otty.subscribeToPoll(['UserChannel'], {}, 5000, '/api/poll', '/api/pollsub')

	//the old bandaid
	tripwire_init(isDev) //<- attaches a buncha stuff to the document

//stimulus js, which is fine,
	const application = Application.start()
	application.is_dev = isDev
	application.load(definitionsFromContext(controllerContext))

//google
	midflip.oneClickSignInPing()

//service worker / Progressive Web App thingy. Should add PWAs to otterly lol.
//See install controller and its partner sidecard
	midflip.deferredPrompt = undefined
	midflip.deferredPromptAppears = []

	window.addEventListener('beforeinstallprompt', (e) => {
		// Prevent Chrome 67 and earlier from automatically showing the prompt
		e.preventDefault();
		// Stash the event so it can be triggered later.
		midflip.deferredPrompt = e;

		for(let x = 0; x < midflip.deferredPromptAppears.length; x += 1) {
			let e = midflip.deferredPromptAppears[x]
			e.style.display = 'block'
		}
	});
}

//make sure we have not already hit this. Scenarios:
//	1. we re-pack all our javascript stuff, and the script url changes, and then we go to a different page, and bam.
//		two different scripts were loaded.
//	2. one of the pages, like a static page, for some reason, dont have no javascript! head is morphed to reflect that.
//		hit the back button, bam! The script is back. Does it run twice? I have no idea! I have no idea what happens in these
//		cases. But that is why we check.
//	3. there was something in an old script that broke things significantly. We fix it, but those plebs on the old system (
//		not actually i love you all very much) still have it broken! Thats when we increase the midflipJsVersion, forcing the
//		window to reload. Would be cool if it could be a hash just sayin. But @ the point of building the javascript IG we dont
//		know what the hash is yet. Not really worth doing some weird gsub insert where-ever that would need to be.
let midflipJsVersion = 10
// let application
if(window.midflip && (( window.midflip.version != midflipJsVersion ) || window.midflip.version == undefined)){
	window.location.reload()
} else if(!(window.midflip)){
	startApp(midflipJsVersion)
}