export default {
	unitName: "AlterPostCard",
	onConnected() {
		this.moveImages();
	},
	async awaitIms(){
		let proms = []
		for(let im of Array.from(this.el.qsa('img'))){
		  proms.push(
			new Promise((resolve) => {
			  im.onload = resolve
			  if(im.complete){resolve()}
			})
		  )
		}
		for(let p of proms){ await p }
		return "OK"
	},

	// Handles image positioning and text adjustment
	async moveImages() {
		this.id = Math.random()
		let x  = await this.awaitIms()

		let img = this.el.qs(".post_card_img");
		let sideBySide = this.el.qs(".sideBySide");
		let content = this.el.qs(".post_card_text_short") || this.el.qs(".post_card_text_tiny") || this.el.qs('.post-card-text-cont')

		if (img){
		  let ratio = (img.naturalHeight / img.naturalWidth)

		  // Is image horizontal??
		  if(ratio < 1.1){
			let textHeight = 70

			if (sideBySide) {
				sideBySide.classList.add("sideBySideSyke")
			}
			this.traverseAndTrimContainer(content, textHeight, content)
			
			let title = this.el.qs(".post-header-row")
			title.classList.remove("hasSideImage")
		  }
		  else{
			// vertical image
			this.adjustText()
		  }
		}
		else {
			let textHeight = 130
			this.traverseAndTrimContainer(content, textHeight, content)
		}
	},

	// VERTICAL IMAGE - SIDEBYSIDE - reduce text to height of image. 
	// Finds height of image & adjusts image css to bring it inline with title.
	// Adjusts text content to fit image height
	adjustText() {
		const img = this.el.qs(".post_card_img");
		const imgContainer = this.el.qs(".post_card_img_container");
		const content = this.el.qs(".post_card_text_short") || this.el.qs(".post_card_text_tiny") || this.el.qs('.post-card-text-cont ')
		const title = this.el.qs(".post-header-row");
	
		// Align image with the top of the title
		const titleTop = title.getBoundingClientRect().top;
		const imgContainerTop = imgContainer.getBoundingClientRect().top;
		let topAdjustment = imgContainerTop - titleTop;
		let offset = 2 

		// Check if it's a mobile device
		if (window.innerWidth <= 768) {  // Adjust this breakpoint as needed
			offset = 6;  // Example value, adjust as needed
		}

		topAdjustment += offset;
		imgContainer.style.marginTop = `${-topAdjustment}px`;
	
		// Calculate available height for text
		const imageBottom = img.getBoundingClientRect().bottom;
		const contentTop = content.getBoundingClientRect().top;
		const availableTextHeight = imageBottom - contentTop;
		
		// console.log('adjustText - Before trimming:', {availableTextHeight, currentContentHeight: content.offsetHeight});

		// this.trimContentToFit(content, availableTextHeight);
		this.traverseAndTrimContainer(content, availableTextHeight, content)
		
		// console.log('adjustText - After trimming:', {availableTextHeight, finalContentHeight: content.offsetHeight});
		// console.log({
		// 	"title": title.textContent,
		// 	"Viewport Width": window.innerWidth,
		// 	"Available Text Height": availableTextHeight,
		// 	"Final Content Height": content.offsetHeight
		// });
	},
	_getLastPos(list){
		list = Array.from(list).reverse()
		for(let x of list){
			if( x.nodeType === Node.TEXT_NODE && ( x.textContent.trim() != '' || x.parentElement.nodeName == 'LI') ){
				return x
			} else if( x.nodeType !== Node.TEXT_NODE){
				let z = this._getLastPos(x.childNodes)
				return (z ? z : x)
			}
		}
		return false
	},
	traverseAndTrimContainer(node, targetHeight, container){	
		const initialHeight = container.offsetHeight;

		if (initialHeight > targetHeight) {
			// Only remove cc-elements if trimming is needed
			this.ccThing = container.qs('.cc-container')
			this.ccThing.remove()
			this.traverseAndTrim(node, targetHeight, container);
			
			let ltn = this._getLastPos(container.childNodes)
			if(ltn){ltn.after(this.ccThing)}
		}
	},
	traverseAndTrim(node, targetHeight, container) {
	
		if (container.offsetHeight <= targetHeight) { return true }
	
		if (!node.children) { //children is not defined on text nodes
			return this.trimTextNode(node, targetHeight, container);
		}
	
		//text nodes are included in childNodes
		for (let child of Array.from(node.childNodes).reverse()) {	
			let ok = this.traverseAndTrim(child, targetHeight, container);
	
			if (!ok && document.contains(child)) {
				node.removeChild(child);
			}

			if (container.offsetHeight <= targetHeight) { return true }
		}
	
		return false;
	},
	
	trimTextNode(textNode, targetHeight, container) {	
		let words = textNode.textContent.split(/\s+/);
		let currentText = words.join(' ');

		let y = false
		while (container.offsetHeight > targetHeight && words.length > 0) {
			y = true
			this.any_y = true
			words.pop();
			currentText = words.join(' ');
			textNode.textContent = currentText;
		}
	
		if(words.length == 0){
			let pe = textNode.parentElement
			if(pe.childNodes.length == 1){
				pe.remove()
			} else {
				textNode.remove()
			}
		} else{
			let cclen = ( y ? this.ccThing.textContent.length + 3 : 0 )
			currentText = currentText.substr(0, currentText.length - cclen)
			if(!currentText.includes('…')){ textNode.textContent = currentText + (this.any_y ? '…' : '')}
		}
		return container.offsetHeight <= targetHeight
	},

	//NO LONGER USED (but maybe in future)
	// Retrieves comment count elements


	insertCCElements(content, ccElements) {
		if (!ccElements || !ccElements.childNodes.length) {
			return;
		}
	
		// Find the last non-empty node (text or element)
		let lastNode = content.lastChild;
		while (lastNode && (lastNode.nodeType === Node.TEXT_NODE && lastNode.textContent.trim() === '') || 
			   (lastNode.nodeType === Node.ELEMENT_NODE && lastNode.innerHTML.trim() === '')) {
			lastNode = lastNode.previousSibling;
		}
	
		if (lastNode) {
			// Create a wrapper span for both last content and cc-elements
			const wrapperSpan = document.createElement('span');
			wrapperSpan.className = 'd-inline';
	
			if (lastNode.nodeType === Node.TEXT_NODE) {
				// For text nodes, wrap the text and cc-elements together
				const textSpan = document.createElement('span');
				textSpan.textContent = lastNode.textContent;
				wrapperSpan.appendChild(textSpan);
				lastNode.parentNode.replaceChild(wrapperSpan, lastNode);
			} else {
				// For element nodes, move the last element's contents into the wrapper
				while (lastNode.firstChild) {
					wrapperSpan.appendChild(lastNode.firstChild);
				}
				lastNode.appendChild(wrapperSpan);
			}
	
			// Add cc-elements to the wrapper
			Array.from(ccElements.childNodes).forEach(child => wrapperSpan.appendChild(child.cloneNode(true)));
		} else {
			// If no non-empty node found, append cc-elements directly to content
			content.appendChild(ccElements);
		}
	}
}